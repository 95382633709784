
/*
    搜索列表
*/
.sug-loading {
  background: url(/img/common/loading2.gif) no-repeat right center;
}
.sug-wrap {
  position: absolute;
  z-index: 101;
}
.sug-default {
  width: 528px;
  overflow: hidden;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  display: none;
  position: relative;
}
.sug-default h2 {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  font-weight: bold;
  color: #f8860f;
  text-indent: 20px;
}
.sug-default h2 span {
  font-size: 12px;
  margin-left: 10px;
  font-weight: normal;
  color: #bcbcbc;
}
.sug-default dl {
    position: relative;
    margin-top: 15px;
}
.sug-default dt {
  width: 90px;
  color: #333;
  text-align: right;
  font-size: 12px;
  font-family: 'SimSun';
  position: absolute;
  left: 0;
  top: 0;
}
.sug-default dd {
  margin-left: 100px;
  _margin: 0;
  _padding-left: 100px;
  overflow: hidden;
}
.sug-default ul {
  overflow: hidden;
  *zoom: 1;
  border-bottom: 1px solid #f2f2f2;
  margin-left: -1px;
}
.sug-default li {
  background: url("/imgs/base/solid.png") left 3px no-repeat;
  margin-bottom: 1px;
  float: left;
  padding-bottom: 15px;
  padding-left: 5px;
}
.sug-default li:last-child {
    border: none;
}
.sug-default li a {
  display: inline-block;
  color: #888;
  height: 16px;
  line-height: 16px;
  padding: 0 5px;
  margin-right: 5px;
  white-space: nowrap;
}
.sug-default li a.selected,
.sug-default li a:hover {
  background-color: #f8860f;
  text-decoration: none;
  color: #FFFFFF;
}
.sug-list {
  border: 1px solid #dbdbdb;
  background-color: #fff;
  width: 528px;
}
.sug-list h2 {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  font-weight: normal;
  color: #bcbcbc;
  text-indent: 10px;
}
.sug-list li {
  height: 29px;
  border-bottom: 1px solid #f2f2f2;
  overflow: hidden;
  line-height: 29px;
}
.sug-list li a {
  display: block;
  height: 29px;
}
.sug-list li a:hover {
  text-decoration: none;
}
.sug-list li .word {
  float: left;
  width: 300px;
  padding-right: 5px;
  height: 29px;
  overflow: hidden;
  text-indent: 10px;
  color: #0c80e2;
  font-size: 16px;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.sug-list li .word em {
    font-weight: bold;
}
.sug-list li .type {
  float: left;
  width: 120px;
  height: 29px;
  overflow: hidden;
  color: #333;
}
.sug-list li .num {
  float: left;
  width: 70px;
  height: 29px;
  overflow: hidden;
  color: #888;
}
.sug-list li.active {
  background-color: #f2f2f2;
}