/* 版块ICONS */
.m-statue {
    .menu-recommend {
        h3 {
            background: url("/imgs/icon/single/statue_v3.png") no-repeat;
            background-position: 0px 5px;
        }

    }

}

.m-eiffel {
    .menu-recommend {
        h3 {
            background: url("/imgs/icon/single/eiffel_v3.png") no-repeat;
            background-position: 0px 5px;
        }

    }

}

.m-kangaroo {
    .menu-recommend {
        h3 {
            background: url("/imgs/icon/single/kangaroo_v3.png") no-repeat;
            background-position: 0px 7px;
        }

    }

}

.m-earth {
    .menu-recommend {
        h3 {
            background: url("/imgs/icon/single/earth_v3.png") no-repeat;
            background-position: 0px 5px;
        }

    }

}

/* 定位标签 */
.item-tag {
    position: absolute;
    right: -12px;
    top: -17px;
    display: block;
    width: 20px;
    height: 17px;
    text-indent: -99999px;
    background-repeat: no-repeat;
}

.nav {
    .item-tag {
        right: 1px;
        top: -3px;
    }

}

.sub-in {
    .item-tag {
        right: -16px;
        top: -11px;
    }

}

.has-tag {
    position: relative;
}

.item-tag-hot {
    background-image: url(/imgs/icon/small_tag_hot.png);
}

.item-tag-new {
    background-image: url(/imgs/icon/small_tag_new.png);
}

/* 热门目的地 */
.classify {
    .home {
        background: url("/imgs/icon/arrow_d.png") no-repeat  190px center;
    }

    &.classify-hover,
    &:hover {
        .home {
            background: url("/imgs/icon/arrow_u.png") no-repeat  190px center;
        }

    }

}

/* HEADER ICONS END */