// 字体公共模块组件

// 字体加载
.font_face(@fontname, @url: "/style/fonts"){
	font-family+: @fontname;
	-webkit-font-smoothing: antialiased;
	-webkit-text-stroke-width: 0.2px;
	@font-face {
		font-family: @fontname;
		src: url("@{url}/@{fontname}.eot");
		src: url("@{url}/@{fontname}.eot?#iefix") format("embedded-opentype"), url("@{url}/@{fontname}.woff") format("woff"), url("@{url}/@{fontname}.ttf") format("truetype"), url("@{url}/@{fontname}.svg#iconfont") format("svg");
	}
}

// 微软雅黑字体
.font_msyh() {
    font-family+: "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1";
}

// 宋体字体
.font_simsun() {
	font-family+: SimSun, "\5B8B\4F53";
}
