@bule: #0090F2;

.tff-service {
    text-align: center;
    line-height: 1.5;
    background: #ecf5ff;
    font-size: 14px;
    position: fixed;
    top: 0px;
    bottom: 0px;
    width: 44px;
    right: 0px;
    min-height: 500px;
    display: block;
    padding: 0 0 0px 0;
    border: 0px solid #DEDEDE;
    box-shadow: 1px 0 5px #888888;
    z-index: 101;
    font-family: "microsoft yahei";

    li {
        height: 44px;
        width: 44px;
        background: url(/imgs/icon/tff-service_4.png) no-repeat center;
        margin: 0px;
    }

    .ser-box {
        display: block;
        width: 44px;
        height: 44px;
        color: #fff;
        position: relative;

        &:hover {
            .side-box {
                display: block;
            }

            .text {
                display: block;
            }

        }

    }

    .ser-banner {
        height: 128px;
        width: 44px;
        position: relative;
        background: none;

        .ban-big {
            opacity: 0px;
            position: absolute;
            right: -210px;
            top: 0px;
            width: 204px;
            height: 128px;

            img {
                width: 204px;
                height: 128px;
            }

        }

        .ban-small {
            a {
                display: block;
                width: 44px;
                height: 128px;
            }

            img {
                width: 44px;
                height: 128px;
            }

        }

        &:hover {
            .ban-big {
                opacity: 1;
                right: 44px;
            }

        }

    }

    .text {
        position: absolute;
        padding: 4px 4px;
        width: 36px;
        overflow: hidden;
        line-height: 18px;
        right: 44px;
        display: none;
        top: 0px;
        background-color: #0090F2;
    }

    .side-box {
        white-space: pre-wrap;
        position: absolute;
        text-align: left;
        color: #4e4e4e;
        display: none;
    }

    .ser-tel {
        background-position: center unit(-(44 * 0), px);

        .side-box {
            width: 170px;
            height: 126px;
            right: 44px;
            top: 0px;
            color: #fff;
            padding: 15px 10px 15px 20px;
            line-height: 18px;
            background-color: #0090F2;
        }

        &:hover {
            background-position: center unit(-(44 * 1), px);
            background-color: #0090F2;
        }

    }

    .ser-online {
        background-position: center unit(-(44 * 2), px);

        &:hover {
            background-position: center unit(-(44 * 3), px);
            background-color: #0090F2;
        }

    }

    .ser-wei {
        background-position: center unit(-(44 * 6), px);

        .side-box {
            bottom: 0px;
            width: 93px;
            height: 90px;
            white-space: normal;
            right: 44px;
        }

        &:hover {
            background-position: center unit(-(44 * 7), px);
            background-color: #0090F2;
        }

    }

    .ser-cart {
        background-position: center unit(-(44 * 4), px);
        position: relative;

        .text {
            line-height: 36px;
        }

        .cart-num {
            width: auto;
            height: auto;
            min-width: 14px;
            min-height: 14px;
            position: absolute;
            top: 6px;
            right: 6px;
            font-style: normal;
            line-height: 12px;
            background: url(/imgs/icon/cartnum.png) no-repeat center;
            background-size: 100% 100%;
        }

        &:hover {
            background-position: center unit(-(44 * 5), px);
            background-color: #0090F2;
        }

    }

    .ser-qus {
        position: absolute;
        bottom: 150px;
        right: 0px;
        background-position: center unit(-(44 * 8), px);

        &:hover {
            background-position: center unit(-(44 * 9), px);
            background-color: #0090F2;
        }

    }

    .ser-top {
        position: absolute;
        bottom: 86px;
        left: 0px;
        background-position: center unit(-(44 * 10), px);
        display: none;

        &:hover {
            background-position: center unit(-(44 * 11), px);
            background-color: #0090F2;
        }

    }

}