/* by stephan
首页改版
所有页面通用reset.less */

/* rest.module.less */
html {
    min-height: 100%;
    font-size: 625%; /* IE6下兼容position: fixed时防抖，写背景图片时请使用body标签 */
    _background-image: url(about:blank);
    _background-attachment: fixed;
}

body {
    color: #333;
    font-size: 12px;
    font-size: .12rem;
    line-height: 1.5;
    background-color: #f2f2f2;
}

body,
td,
th {
    font-family: Arial, "\5B8B\4F53", Verdana;
    font-size: 12px;
}

body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
form,
input,
textarea,
table {
    margin: 0;
    padding: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var,
optgroup {
    font-style: normal;
    font-weight: normal;
}

ul,
ol,
li {
    margin: 0;
    padding: 0;
}

ul,
ol {
    list-style: none;
}

li {
    list-style: inherit;
}

img {
    border: 0;
    vertical-align: middle;
}

a {
    color: #77726b;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

textarea {
    resize: none;
}

table {
    border: 0 none;
    border-collapse: collapse;
    border-spacing: 0;
}

th {
    text-align: left;
}

h1,
h2,
h3,
h4 {
    font-size: 100%;
    font-weight: bold;
}

input,
button,
textarea,
select,
option {
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
}

input[type="text"]:focus,
input[type="password"]:focus,
textarea:focus {
    outline: medium none;
    margin: 0;
    border-raduis: 0;
}

.clearfix:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
}

.clearfix {
    zoom: 1;
}

.undis {
    display: none;
}

.yahei {
    @import url("../fonts.module.less");
    .font_msyh();
}

.place-holder {
    color: #ccc;
}

.ellipsis {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

// 价格加密
.fake-price {
    visibility: hidden;
}

.partner a {
    cursor: default;
}