.qqcb-wrap {
	background: #545652;
	height: 25px;
}
.qqcb {
	line-height: 25px;
	font-size: 12px;
	margin: 0 auto;
	color: #ffed8c;
	width: 1200px;
	a {
		color: #97d3fb;
	}
	div {
		display: inline;
		float: right;
	}
	img {
		vertical-align: middle;
		padding-right: 3px;
	}
}