/* by stephan
首页改版
所有页面通用footer.module.less */
.in_iframe {
    .footer,
    .copyright-wrap {
        display: none;
    }

}

.footer {
    background: #fff;
}

.foot {
    width: 1200px;
    height: 240px;
    margin: 0 auto;
    position: relative;
    background: #fff;
}

.foot dl {
    width: 160px;
    float: left;
    font-family: 'microsoft yahei';
}

.foot dt {
    font-size: 20px;
    color: #252525;
    padding: 50px 0 20px;
}

.foot dd a {
    display: block;
    color: #252525;
    padding-bottom: 15px;
    font-size: 14px;

    &:hover {
        text-decoration: underline;
    }

}

.follow {
    font-family: 'microsoft yahei';
    width: 240px;
    height: 130px;
    padding-left: 28px;
    position: absolute;
    right: 0;
    top: 32px;
}

.follow ul {
    margin-right: -20px;
}

.follow li {
    float: left;
    padding-right: 20px;
}

.rss {
    clear: both;
    padding-top: 25px;
}

.rss-wrap {
    position: relative;
    height: 30px;
}

.rss .rss-ipt {
    color: #111;
    width: 154px;
    padding: 4px 5px;
    height: 20px;
    line-height: 20px;
    border: 0;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #c4c4c4;
    margin-top: 0;
}

.rss-ipt:focus {
    border: 1px solid #7fc5f9;
}

.rss a {
    display: block;
    width: 85px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    background: #0090f2;
    position: absolute;
    right: 0;
    top: 0;
}

.rss a:hover {
    text-decoration: none;
}

.copyright-wrap {
    background: #fff;

    a:hover {
        text-decoration: underline;
    }

}

.copyright {
    color: #4f4f4f;
    line-height: 18px;
    overflow: hidden;
    width: 1200px;
    padding-top: 25px;
    margin: 0 auto;
    background: #fff;
}

.copyright-info {
    position: relative;
    color: #b4b4b4;
    line-height: 22px;
    padding: 25px 0 45px 0;
    font-size: 12px;

    .cert1 {
        position: absolute;
        right: 0;
        bottom: 45px;

        a {
            margin-left: 12px;
        }

    }

}

.cr-text {
    text-align: left;
}

.foot-icon {
    position: relative;
}

.foot-icon p {
    left: 15px;
    position: absolute;
    top: 3px;
    width: 100px;
    font-size: 16px;
    color: #555;
}

.partner {
    width: 1262px;
    margin-left: -62px;

    .row-last {
        float: right;
    }

}

.foot-icon a {
    float: left;
    display: block;
    margin-left: 62px;
    margin-bottom: 20px;

    img {
        max-height: 39px;
    }

}

.foot-tip {
    cursor: help;

    &:hover {
        text-decoration: underline;

        .foot-tip-txt {
            display: block;
        }

    }

}

.foot-tip-txt {
    background-color: #7fc5f9;
    color: #fff;
    display: none;
    left: 0;
    padding: 8px;
    position: absolute;
    top: 10px;
    border-radius: 2px;
    z-index: 2;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.copyright-info a {
    color: #b4b4b4;
}

.frend-links {
    padding: 0 0 45px 0;
    font-family: "microsoft yahei";
}

.frend-links p {
    padding: 15px 0 0 0;
    line-height: 28px;
    font-size: 14px;
    color: #777;
}

.frend-links a {
    color: #b4b4b4;
    display: inline-block;
    font-size: 14px;
    color: #777;
}

.holder_fixed {
    position: fixed;
    bottom: 0;
    z-index: 2;
    width: 100%;
    background: url(/img/vip_cpm.png) no-repeat center center;
    _position: absolute;
    _bottom: auto;
    _width: 100%;
    _position: absolute;
    _bottom: auto;
    _width: 100%;
    color: #fff;

    .bottom-banner-holder {
        width: 1200px;
        height: 100px;
        position: relative;
        margin: 0 auto;
        margin-top: 2px;

        .bottom-close {
            display: block;
            width: 1200px;
            margin: 0 auto;
            height: 100px;
            position: relative;

            .holder_fixed_text {
                position: absolute;
                right: 155px;
                width: 480px;
                top: 15px;
                color: #fff;
                font-size: 16px;
                line-height: 21px;

                tip,
                span {
                    color: #3e99dc;
                }

                tip:hover,
                span:hover {
                    text-decoration: underline;
                }

            }

        }

        em {
            background: url(/img/close_vip.png) no-repeat center right;
            _background: url(/img/close_vip_ie6.png) no-repeat center right;
            position: absolute;
            bottom: 32px;
            width: 36px;
            height: 37px;
            right: 29px;
            cursor: pointer;
            z-index: 2;
        }

    }

}

//底部内联seow
#links-wrap {
    padding-left: 0px;
    padding-top: 40px;
    color: #b4b4b4;
    font-size: 14px;
    line-height: 32px;

    .new-links {
        padding-left: 0px;
        padding-bottom: 0px;

        a {
            margin-right: 5px;
            color: #b4b4b4;
        }

        .curr {
            //padding-bottom: 10px;
        }

    }

}

//footer改版最底部分
.new-foot {
    font-family: "microsoft yahei";
    background: #434343;

    .cr-text {
        width: 835px;
    }

}

.foot {
}

.new-foot-warp {
    width: 1200px;
    margin: 0 auto;
}

.foot-tel {
    padding-left: 50px;
    height: 46px;
    margin: 25px 0;
    font-size: 20px;
    font-family: Arial;
    font-weight: bold;
    color: #777;
    line-height: 23px;
    background: url(../imgs/icon/foot-tel.png) left no-repeat;

    span {
        font-weight: normal;
        font-family: "microsoft yahei";
        padding-left: 20px;
    }

}

.gz-item {
    padding-left: 50px;
    width: 60px;
    font-size: 14px;
    color: #777;
    float: left;
    display: block;
    height: 40px;
    line-height: 40px;
    position: relative;
    background: url(../imgs/icon/foot-weixin.png) left no-repeat;
    cursor: pointer;

    &:hover {
        background-image: url(../imgs/icon/foot-weixin-cur.png);
        color: #0090f2;
        text-decoration: underline;

        .gz-dia {
            display: block;
            z-index: 1;
        }

    }

    &.weibo {
        margin-right: 20px;
        background-image: url(../imgs/icon/foot-weibo.png);

        &:hover {
            background-image: url(../imgs/icon/foot-weibo-cur.png);
        }

    }

}

.gz-dia {
    display: none;
    position: absolute;
    top: 45px;
    left: 0;
    margin-left: -45px;
    text-align: center;
    width: 132px;
    height: 139px;
    background: url(../imgs/icon/foot-gz-diabk.png) center no-repeat;

    img {
        padding-top: 18px;
    }

}

//新底部w-1000
.w-1000 {
    .footer {
        .foot {
            dl {
                width: 130px;
            }

        }

    }

    .copyright-wrap {
        .foot-icon a {
            margin: 0 0 20px 62px;
        }

    }

    .new-foot-warp {
        width: 1000px;
    }

    .cr-text {
        width: 600px;
    }

    .partner {
        width: 1062px;
    }

}

//proj2726 底部浮窗
.bg-opacity(@r, @g, @b, @a) {
    @bg: rgba(@r, @g, @b, @a);
    // IE6-IE8下滤镜模拟50%透明黑色背景
    filter: e(%("progid:DXImageTransform.Microsoft.Gradient(StartColorStr='%d', EndColorStr='%d')", argb(@bg), argb(@bg)));
    // url(about:blank)是为了防止IE下鼠标点穿半透明遮罩，无此需求时不写此行
    background: url(about:blank);
    // IE9+ Firefox Chrome Safari Opera
    background: @bg;

    :root & {
        // IE9需要关闭滤镜，否则会同时显示半透明背景和半透明滤镜
        filter: none;
    }

}

//底部Banner展示
.bottom-banner-fixed {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    background: url("/imgs/icon/bg_black.png");
    background: rgba(0, 13, 19, 0.8);

    .inner {
        position: relative;
        width: 100%;
        height: 165px;
        margin: 0 auto;
    }

    .pic-holder {
        position: absolute;
        bottom: 0;
        right: -400px;
        width: 1811px;
        height: 230px;
    }

    .pic-holder-tw {
    }

    .bottom-banner-fixed-close {
        position: absolute;
        top: 13px;
        right: 80px;
        display: block;
        background: url(../../imgs/icon/icon_close_black.png);
        width: 26px;
        height: 26px;
    }

}