/* by stephan
首页改版
头部通用header.module.less */
.in_iframe {
    .home-top-banner-holder,
    .top-bar-wrap,
    .header-wrap,
    .sug-wrap,
    .menu-wrap {
        display: none;
    }

}

/* 顶部广告栏 */
.home-top-banner-holder {
    width: 100%;
    height: 0px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
}

.home-top-banner-holder .small,
.home-top-banner-holder .big {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.home-top-banner-holder .small {
    height: 100px;
    z-index: 1;
}

.home-top-banner-holder .big {
    height: 450px;
    top: 100px;
}

.home-top-banner-holder .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
    color: #FFF;
    display: none;
    font-size: 12px;
    padding: 5px 8px;
    border: 1px solid #FFF;
    border-radius: 5px;
    cursor: pointer;
}

.home-top-banner-holder a {
    display: inline-block;
}

.small-1000 a,
.big-1000 a {
    margin-left: -500px;
}

/*
    顶部菜单
*/
.top-bar-wrap {
    height: 29px;
    background: #f2f2f2;
    position: relative;
    border-bottom: 1px solid #eee;
    *z-index: 3;

    &:hover {
        z-index: 2;
    }

    .level {
        background: no-repeat right center;
        text-overflow: ellipsis;
        vertical-align: middle;
        padding-right: 20px;
        white-space: nowrap;
        overflow: hidden;
        max-width: 4em;
    }

    .level0,
    .normal-level {
        background-image: url("/imgs/icon/normal_level.png");
    }

    .level1,
    .silver-level {
        background-image: url("/imgs/icon/silver_level.png");
    }

    .level2,
    .gold-level {
        background-image: url("/imgs/icon/gold_level.png");
    }

    .level3,
    .platinum-level {
        background-image: url("/imgs/icon/platinum_level.png");
    }

    .level4,
    .diamond-level {
        background-image: url("/imgs/icon/diamond_level.png");
    }

    .top-bar {
        height: 29px;
        width: 1200px;
        margin: 0 auto;
        background: #f2f2f2;
        line-height: 29px;
        position: relative;
        color: #b5b5b5;
    }

}

.top-bar {
    .my-tours {
        width: 90px;
        margin-right: 0;
    }

    .lang {
        width: 65px;
        margin-right: 0;
    }

    .my-tours .slide-item-column {
        width: 92px;
    }

    .lang .slide-item-column {
        width: 67px;
        border-bottom: 1px solid #dbdbdb;
        font-family: Arial, Verdana;
    }

    /*app*/
    .app {
        width: 118px;
        position: relative;

        .slide-item-column {
            // display: block;
        }

        &:hover {
            .app-link {
                height: 30px;
                z-index: 1;
                background-position: 99px 15px;
                background-color: #fff;

                .app-logo {
                    width: 7px;
                    height: 12px;
                    background: url("/imgs/icon/app.png");
                    background-position: -9px -16px;
                    display: inline-block;
                    position: relative;
                    right: 19px;
                    top: 2px;
                }

            }

        }

    }

    .app-link {
        width: 118px;
        height: 29px;
        padding: 0;
        position: absolute;
        text-align: center;
        background-position: 99px 15px;

        .app-logo {
            width: 7px;
            height: 12px;
            background: url("/imgs/icon/app.png");
            display: inline-block;
            position: relative;
            right: 19px;
            top: 2px;
        }

    }

    .app-code {
        background: #fff;
        width: 148px;
        height: 148px;
        position: absolute;
        left: -30px;
        top: 0px;
        border: 1px solid #dbdbdb;

        .words {
            display: block;
            font-size: 14px;
            font-family: 'Microsoft Yahei';
            color: #777777;
            line-height: 14px;
            margin: 10px 30px 10px 34px;
            border-style: none;
        }

        .img {
            background: url("/imgs/icon/app.png");
            background-position: -24px;
            height: 98px;
            width: 98px;
            margin: 0px 25px 10px 25px;
        }

    }

}

/*update by Leon 2015年5月28日 16:21:48*/

/*头部*/
.slide-item {
    margin-right: 10px;
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;

    > a {
        background: url("/imgs/icon/up.png") right center no-repeat;
    }

    .slide-item-column a {
        padding-right: 0;
        display: block;
        background: none;
        border-left: 1px solid #dbdbdb;
        border-right: 1px solid #dbdbdb;
        color: #888;
        height: 28px;
        line-height: 28px;
    }

    .slide-item-column a:hover {
        background: #f2f2f2;
        text-decoration: none;
    }

    .slide-item-column .fast-order-search {
        border: 1px solid #bee1fb;
        color: #7fc5f9;
        padding: 0 2px;
    }

    .slide-item-column .fast-order-search:hover {
        background: #7fc5f9;
        color: #fff;
    }

    &.slide-item-active,
    &:hover {
        background: #fff;
        border-color: #dbdbdb;

        >.undis {
            display: block;
        }

        .cart {
            background: url("/imgs/icon/cart_bg.png") no-repeat 5px center #fff;
        }

        > a {
            background: url("/imgs/icon/down.png") right center no-repeat;
            color: #ff6c00;
        }

    }

}

.undis .undis {
    display: none;
}

.slide-item-column {
    position: absolute;
    background: #fff;
    left: -1px;
    top: 29px;
}

.top-bar .orange {
    color: #ff6c00;
}

.top-bar .bold {
    font-weight: bold;
}

.top-bar .to-orange:hover {
    color: #ff6c00;
}

.top-bar a {
    color: #b5b5b5;
    display: inline-block;
    padding: 0 10px
}

.user a {
    padding: 0;
}

.site-nav {
    position: absolute;
    right: 0;
    top: 0;
}

.top-bar li {
    display: inline;
    float: left;
    height: 29px;
    position: relative;
}

.top-bar .solid {
    padding-right: 10px;
    margin-right: 10px;
    background: url("/imgs/icon/solid.png") no-repeat right center;
}

.top-bar .my-cart {
    padding-right: 0;
}

.site-nav .cart {
    background: url("/imgs/icon/cart_bg.png") no-repeat 5px center;
    padding-left: 25px;
    position: relative;
}

.site-nav .club {
    padding-left: 25px;
    background: url("/imgs/icon/club.png") no-repeat 4px center;
}

.site-nav .club:hover {
    background: url("/imgs/icon/club_bg.png") no-repeat 4px center;
}

.site-nav .notification {
    padding-left: 25px;
    background: url("/imgs/icon/notification_bg_h.png") no-repeat 5px center;
}

.site-nav .notification:hover {
    background: url("/imgs/icon/notification_bg.png") no-repeat 5px center;
}

/* 我的购物车 */
.cart-list {
    background: #fff url(/imgs/ajaxload_info/18#2a92de#ffffff.gif#data-uri) no-repeat 31px 12px;
    *background: #fff url(/imgs/ajaxload_info/18#2a92de#ffffff.gif) no-repeat 31px 12px;
    border: 1px solid #dbdbdb;
    left: -1px;
    position: absolute;
    top: 24px;
    width: 368px;
}

.cart-list .go-cart {
    display: inline-block;
    background: #ff6215;
    border-radius: 1px;
    bottom: 10px;
    color: #fff;
    height: 24px;
    line-height: 24px;
}

.go-top-wrap {
    border-top: 1px solid #F2F2F2;
    width: 348px;
    margin: 0 auto;
    text-align: right;
    padding: 10px 0;
    margin-top: 10px;
}

.cart-list .go-cart:hover {
    background: #f8860f;
    text-decoration: none;
}

.cart-list .cart-tit {
    color: #333;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 0;
    padding-left: 10px;
    background: #fff;
}

.cart-list .cart-empty {
    font-size: 12px;
    padding-left: 10px;
    padding: 13px 0 0 30px;
    color: #555555;
    background: #fff;
}

.cart-list li {
    padding-bottom: 15px;
    position: relative;
    height: 40px;
    display: block;
    float: none;
}

.cart-list li:hover {
    background: #f2f2f2;
}

.cart-list li .name {
    color: #555;
    line-height: 20px;
    height: 20px;
    padding: 8px 10px 0 80px;
    background: none;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cart-list li .price-lists {
    padding-left: 80px;
}

.cart-list li .img-wrap {
    display: block;
    height: 40px;
    left: 10px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 8px;
    width: 50px;
    background: none;
}

.cart-list li .old-price {
    text-decoration: line-through;
}

.cart-list li span {
    color: #ff6c00;
    margin-left: 5px;
}

/* header */
.header .logo {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    padding: 28px 0 0 25px;
    width: 161px;
}

.logo a {
    display: block;
    overflow: hidden;
    height: 68px;
    width: 143px;
    position: relative;
    z-index: 1;
    background: url('/img/common/logo/logo.png') center center;
}

.solagan {
    position: absolute;
    right: 260px;
    top: 18px;
}

.header-wrap {
    background: #fff;
    min-width: 1000px;
}

.header {
    width: 1200px;
    height: 110px;
    margin: 0 auto;
    position: relative;

    .head-ad {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        width: 80px;
        height: 54px;
        overflow: hidden;
        padding: 30px 0 0 6px;

        img {
            max-width: 100%;
        }

    }

}

.nav-search {
    position: absolute;
    left: 285px;
    top: 30px;

    .search-box {
        position: relative;
        width: 530px;
        height: 40px;
        padding: 0;
    }

}

.search-box .submit {
    border-radius: 0;
    width: 80px;
    height: 40px;
    border: 0;
    position: absolute;
    right: 0;
    top: 0;
    background: url("/imgs/icon/search_btn.png") center center no-repeat #3e99dc;
    cursor: pointer;
}

.search-box .name {
    border: 2px solid #3e99dc;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    padding: 8px 20px;
    width: 410px;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 0;
    border-radius: 0;
}

.search-hot {
    padding-top: 14px;
    color: #888;
}

.search-hot a {
    color: #7fc5f9;
    margin-right: 8px;
}

.nav-app {
    width: 142px;
    height: 37px;
    position: absolute;
    left: 845px;
    top: 32px;

    .nav-app-logo {
        width: 19px;
        height: 35px;
        background: url("/imgs/icon/header-app.png");
        display: block;
        float: left;
        margin-right: 13px;
    }

    .nav-app-title-top {
        display: block;
        float: left;
        font-size: 18px;
        font-family: 'Microsoft Yahei';
        line-height: 1px;
        margin-top: 7px;
        color: #3e99dc;
        font-weight: 500;
    }

    .nav-app-title-bottom {
        display: block;
        float: left;
        font-size: 14px;
        font-family: 'Microsoft Yahei';
        margin-top: 10px;
        color: #777777;

        .nav-app-icon {
            display: inline-block;
            width: 6px;
            height: 12px;
            background: url("/imgs/icon/header-app.png");
            background-position: -26px -15px;
            margin-left: 5px;
        }

    }

}

.w-1000 .nav-app {
    left: 663px;
}

.nav-tel {
    position: absolute;
    right: 0px;
    top: 20px;
    font-size: 16px;
    color: #999;
    line-height: 20px;
    width: 190px;
}

.nav-tel .home-title {
    padding-bottom: 10px;
}

.nav-tel span {
    font-size: 22px;
    color: #777;
    padding-right: 10px;
}

.nav-tel {
    .tel-list-top {
        width: 145px;
        height: 6px;
        padding: 0px 15px;
        display: none;
        background-image: url("../imgs/icon/top-more.png");
        background-repeat: no-repeat;
        background-position: center;
    }

    .tel-list {
        white-space: pre-wrap;
        position: absolute;
        background: #0090F2;
        text-align: left;
        color: #fff;
        display: none;
        width: 170px;
        height: 140px;
        padding: 10px 15px;
        font-size: 14px;
        left: -5px;
    }

    &:hover {
        cursor: pointer;

        .tel-list {
            display: block;
            z-index: 2;
        }

        .tel-list-top {
            display: block;
        }

    }

}

/* menu */
.menu-wrap {
    height: 50px;
    background: #0090f2;
    position: relative;

    .menu {
        width: 1200px;
        margin: 0 auto;
        position: relative;
        background: #0090f2;
        height: 50px;
        _z-index: 2;
    }

}

.menu-wrap:hover .menu {
    z-index: 99;
}

/*左侧菜单栏*/
.classify {
    width: 220px;
    height: 50px;
    background: #ffba13;
    position: relative;
    float: left;

    .home {
        color: #fff;
        height: 50px;
        display: block;
        cursor: pointer;
        font-size: 16px;
        line-height: 50px;
        text-align: center;

        span {
            font-size: 14px;
        }

        a {
            color: #fff;
            font-size: 14px;
        }

        .link-home {
            font-size: 18px;
            margin-right: 20px;
        }

    }

}

.main_menu_box {
    position: relative;
    margin: 0 auto;
    width: 220px;
    z-index: 99;

    &.classify-hover {
        .main-menu {
            display: block;
        }

    }

}

.main-menu {
    border: 2px solid #ffba13;
    background-color: #FFF;
    position: absolute;
    border-top: 0;
    display: none;
    width: 216px;
    right: 490px;
    //默认隐藏，只在首页开启。
}

.menu-item {
    padding-left: 0px;
    border-bottom: 1px solid #f2f2f2;
    // background-position: 10px 8px;

    h3 {
        line-height: 22px;
        font-weight: normal;
        font-size: 16px;
        padding-top: 14px;
        padding-bottom: 12px;
        padding-left: 40px;
        position: relative;

        .tit-tag {
            background: #f27800;
            color: #fff;
            margin: 2px 0px;
            padding: 1px 4px;
            border-radius: 2px;
            font-size: 12px;
        }

        .arrow-r {
            position: absolute;
            top: 12px;
            right: 8px;
            height: 28px;
            width: 7px;
            background: url("/imgs/icon/arrow_r.png") 0px 5px no-repeat;
        }

    }

    &.menu-item-hover,
    &:hover {
        margin-right: -2px;
        z-index: 2;
        box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
        border-bottom: none;
        height: 97px;
        behavior: none;
        background-color: white;

        .sub-menu {
            display: block;
        }

        .menu-recommend {
            background: #fff;
        }

        .arrow-r {
            background-image: url("/imgs/icon/arrow_r_h.png")
        }

    }

}

.item-posr {
    position: relative;
}

.item-last {
    border-bottom: none;

    .menu-recommend {
        height: 97px;
    }

}

.menu-item-h {
    height: 55px;
}

.menu-item-hover {
    // margin-right: -2px;
    // z-index: 2;

    .menu-recommend {
    }

}

:root .menu-item-hover {
}

.menu-item-hover .sub-menu {
}

.menu-recommend {
    height: 96px;
    position: relative;
    padding-left: 12px;

    .rec-box {
        width: 184px;
        height: 44px;
        line-height: 22px;
        padding: 0 20px 0 0;
        overflow: hidden;
        font-family: SimSun, "宋体";

        a {
            max-width: 9em;
            display: inline-block;
            *display: inline;
            *zoom: 1;
            margin-right: 6px;
            line-height: 1.1em;
            white-space: nowrap;
        }

        .h-light {
            color: #FB5F10;

            &:hover {
                text-decoration: underline;
            }

        }

    }

}

.menu-recommend h3 a {
    color: #252525;
}

.menu-recommend h3 a:hover {
    color: #000;
}

.menu-recommend a {
    transition: all 0.3s ease-in;
    color: #777777;
}

.menu-recommend a:hover {
    color: #0090f2;
    text-decoration: none;
}

.sub-menu {
    position: absolute;
    left: 100%;
    top: 0;
    width: 790px;
    _height: 392px;
    min-height: 392px;
    display: none;
    border-style: solid;
    border-color: #D6D6D6;
    border-color: rgba(0, 0, 0, 0.2);
    border-width: 0 2px 2px;
    background-color: #fff;
    background-clip: padding-box;
    background-position: right bottom;
    background-repeat: no-repeat;

    a {
        padding: 0 3px;
        color: #77726b;
        line-height: 18px;
        text-decoration: none;
        display: inline-block;
        *display: inline;
        *zoom: 1;
    }

    /* 热点 */
    .hotspot {
        display: block;
        width: 310px;
        height: 390px;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    /* 偏移图 */
    .offset-img {
        position: absolute;
        right: 0px;
        bottom: 0;
    }

}

.nav {
    height: 50px;
}

.nav .nav-item {
    float: left;

    &:hover,
    &:hover .sub-nav-new {
        background-color: #7fc5f9;
    }

    &:hover .sub-nav-new {
        display: block;
    }

}

.nav-link {
    font-size: 16px;
    font-family: 'microsoft yahei';
    color: #fff;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
}

.nav .spec-nav {
    color: #ffe9a4;
}

.nav-link:hover {
    background: #7fc5f9;
    text-decoration: none;
}

.sub-nav-bg {
    display: none;
    position: absolute;
    left: 220px;
    top: 50px;
    z-index: -1;
    background: #7fc5f9;
    height: 50px;
}

.sub-nav-new {
    width: 1200px;
    position: absolute;
    top: 50px;
    left: 0;
    color: #FFF;

    .sub-in {
        padding-top: 14px;
        padding-bottom: 16px;
        padding-left: 230px;
    }

}

/* nav-item padding-left */
@twoWords: 76;
@fourWords: 107;
@classifyWidth: 220;

.nav-item-usa {
    .sub-in {
        padding-left: unit((@classifyWidth + @twoWords), px);
    }

}

.nav-item-eu {
    .sub-in {
        padding-left: unit((@classifyWidth + @twoWords + @fourWords), px);
    }

}

.nav-item-anz {
    .sub-in {
        padding-left: unit((@classifyWidth + @twoWords + @fourWords + @fourWords), px);
    }

}

.nav-item-custom {
    .sub-in {
        padding-left: unit((@classifyWidth + @twoWords + @fourWords + @fourWords + @fourWords), px);
    }

}

//用车
.nav-item-car {
    .sub-in {
        padding-left: 770px;
    }

}

.w-1000 {

    /* w-1000 nav-item padding-left */
    @twoWords: 52;
    @fourWords: 89;
    @classifyWidth: 220;

    .nav-item-usa {
        .sub-in {
            padding-left: unit((@classifyWidth + @twoWords), px);
        }

    }

    .nav-item-eu {
        .sub-in {
            padding-left: unit((@classifyWidth + @twoWords + @fourWords), px);
        }

    }

    .nav-item-anz {
        .sub-in {
            padding-left: unit((@classifyWidth + @twoWords + @fourWords + @fourWords), px);
        }

    }

    .nav-item-car {
        .sub-in {
            padding-left: 680px;
        }

    }

}

.sub-nav-new a {
    color: #FFF;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    font-family: 'SimSun';
    font-size: 12px;
    padding: 1px 4px;
    text-decoration: none;
    margin: 0 11px 0 0;
}

.sub-nav-new .first {
    border-left: none;
}

.sub-nav-new a:hover {
    background: #fff;
    color: #3e99dc;
    text-decoration: none;
}

.has-sub-nav-active .sub-nav-new {
    display: block;
}

.has-sub-nav .spec {
    background: url("/imgs/icon/up_nav.png") 89px  center no-repeat;
    padding-right: 15px;
}

.nav .has-sub-nav-active .spec {
    background: url("/imgs/icon/down_nav.png") 89px  center no-repeat #7fc5f9;
}

.w-1000 .has-sub-nav .spec {
    background: url("/imgs/icon/up_nav.png") 89px  center no-repeat;
    padding-right: 15px;
}

.w-1000 .has-sub-nav-active .spec {
    background: url("/imgs/icon/down_nav.png") 89px  center no-repeat #7fc5f9;
}

/* 紧急通知 */
#website-tips {
    height: 50px;
    background: url("/imgs/icon/bg_yellow.png");
    width: 100%;
    font-size: 14px;
    color: #ff8200;
    line-height: 50px;
}

.w-1000 .website-tips {
    width: 1000px;
}

.website-tips {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    height: 50px;
    background: url("/imgs/icon/bg_yellow.png");
    overflow: hidden;
}

#website-tips b {
    font-weight: normal;
    display: inline-block;
    padding-left: 30px;
    background: url("/imgs/icon/bg_tips.png") left center no-repeat;
    white-space: nowrap;
}

#close-website-tips {
    position: absolute;
    width: 29px;
    height: 30px;
    text-indent: -999px;
    overflow: hidden;
    background: url("/imgs/icon/icon_close.png");
    top: 10px;
    right: 0;
}

#close-website-tips:hover {
    background: url("/imgs/icon/icon_close1.png");
}

.row-sub {
    margin: 22px 0 0 30px;

    .label-sub {
        float: left;
        width: 80px;
        height: 24px;
        color: #FFF;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-right: 10px;
        text-align: center;
        background: #81D4FA;
    }

    .list-sub {
        float: left;
        width: 600px;
    }

    .col-list {
        float: left;
        width: 118px;
        margin-bottom: 14px;

        b {
            display: block;

            a {
                float: none;
                margin-bottom: 4px;
                color: #353535;
            }

        }

        a {
            white-space: nowrap;
            max-width: 94px;
            *width: 94px;
            text-overflow: ellipsis;
            overflow: hidden;
        }

    }

    .h-light {
        color: #FB5F10;
    }

}

.hot-sub {
    .label-sub {
        background-color: #FF6C00;
    }

}

.horizontal {
    margin-left: -8px;

    li {
        float: left;
        padding: 0 5px;
        margin: 3px 0 18px 0;
        background: url(/imgs/icon/breakup.jpg) 100% 50% no-repeat;
    }

    a {
    }

    .last {
        background-image: none;
    }

}

.list-ad-bottom {
    margin-top: 20px;

    .ad-banner-item {
        width: 49.9%;
        height: 99px;
        float: left;

        a {
            display: block;
            padding: 0;
        }

        img {
            display: block;
            border: none;
        }

    }

}

// ==============================================================
// ============下面是不同状态或者板块的单独处理==================
// ==============================================================

.w-1000 .sub-nav-new {
    width: 1000px;
}

.w-1000 .nav-item .sub-in a {
    padding: 1px 4px;
}

/* SUBMENU HOVER处理 */
.sub-menu {
    .horizontal a:hover,
    .col-list p a:hover {
        text-decoration: none;
        background: #f8860f;
        color: #fff;
    }

}

/* POSR MENU */
.m-item-other .sub-menu {
    border-width: 2px;
    margin-left: -2px;
    min-height: 97px;
    top: -2px;
    _height: 97px;
}

/* 子菜单双栏 */
.menu-has-two() {
    .list-sub {
        width: 374px;
    }

    .col-list {
        width: 49.9%;
    }

}

/* 子菜单三栏 */
.menu-has-three() {
    .list-sub {
        width: 670px;
    }

    .col-list {
        width: 33.3%;
    }

}

/* 一栏双排 */
.col-has-two() {
    .col-sub {
        width: 49.9%;
        float: left;
    }

}

.min-height(@height) {
    min-height: @height;
    _height: @height;
}

/* 不同板块处理 */
.m-item-eu,
.m-item-anz {
    .sub-menu {
        padding-bottom: 34px;
    }

}

.m-item-usa {
}

.m-item-eu {
    .menu-has-three;
    .col-has-two;

    .hotspot {
        width: 270px;
        height: 175px;
    }

}

.m-item-anz {
    .menu-has-two;
    .col-has-two;
}

.m-item-other {
    position: relative;
    *zoom: 1;

    .row-sub {
        margin: 15px 0 0 30px;
    }

    .horizontal li {
        margin: 3px 0 8px 0;
    }

}

.sub-menu .w-350 {
    width: 350px;
}